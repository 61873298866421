import React from 'react';
import * as R from 'ramda';
import { graphql } from 'gatsby';
// import Container from '@material-ui/core/Container'

import SEO from '../components/seo'
import Layout from '../components/layout';

//import loadable from '@loadable/component'
import importedComponent from 'react-imported-component';

const MenuWrapper = importedComponent(() => import('../components/menu/Menu'));
const MenuCategory = importedComponent(() => import('../components/menu/MenuCategory'));


const MenuCategoryPage = ({ data, location }) => {
  const { menuCategory, pageContent, brandSettings } = data;
  const name = R.path(['kategorianNimi'], menuCategory);
  const description = R.path(['kategorianKuvaus', 'kategorianKuvaus'], menuCategory);
  const image = R.path(['kategorianKuva', 'fluid'], menuCategory);
  const hidePrices = R.path(['piilotaHinnat'], menuCategory) ? true : false;
  const products = R.path(['ruokaAnnokset'], menuCategory);
  const disclaimer = R.path(['kategorianAlateksti', 'kategorianAlateksti'], menuCategory);

  const heroData = R.path(['dynaaminenSisalto'], pageContent) && R.path(['dynaaminenSisalto'], pageContent).find(node => node.__typename === 'ContentfulPaanosto')
  const categories = R.path(['dynaaminenSisalto'], pageContent) && R.path(['dynaaminenSisalto'], pageContent).find(node => node.__typename === 'ContentfulMenu');

  const parentHero = {
    image: R.path(['paakuva', 'fluid'], pageContent),
    mobileImage: R.path(['paakuvaMobiili', 'fluid'], pageContent),
    title: R.path(['otsikko'], heroData) ? R.path(['otsikko'], heroData) : R.path(['sivunOtsikko'], pageContent),
    titleColor: R.path(['sivunOtsikonVari'], pageContent) ? R.path(['sivunOtsikonVari'], pageContent) : null,
    content: R.path(['sisaltoteksti', 'sisaltoteksti'], heroData),
    ctaVisible: R.path(['naytaNavigointinappi'], heroData),
    ctaText: R.path(['linkkiteksti'], heroData),
    ctaLink: R.path(['linkkiosoite'], heroData)
  }

  const seoTitle = name
  const seoDesc = description
  const metaImage = R.path(['kategorianKuva', 'file', 'url'], menuCategory)
  const pageURL = location.href

  return (
    <Layout>
      <SEO
        sitename={R.path(['nimi'], brandSettings)}
        seoTitle={seoTitle}
        seoDesc={seoDesc}
        someTitle={seoTitle}
        someDesc={seoDesc}
        image={metaImage}
        url={pageURL}
      />
      <MenuWrapper
        data={{
          content: categories,
          brandSettings: brandSettings,
          parentHero: parentHero,
          slug: R.path(['sivupolku'], pageContent)
        }}
        location={location}
      >
        <MenuCategory
          name={name}
          description={description}
          image={image}
          hidePrices={hidePrices}
          products={products}
          disclaimer={disclaimer}
        />
      </MenuWrapper>
    </Layout>
  );
};

export default MenuCategoryPage;

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query menuCategoryQuery($category: String!, $brand: String!, $parentPage: String!) {
    menuCategory: contentfulMenukategoria(ravintolaketju: {sivupolku: { eq: $brand }}, kategorianNimi: {eq: $category}) {
      id
      kategorianNimi
      kategorianKuvaus {
        kategorianKuvaus
      }
      kategorianKuva {
        file {
          url
        }
        fluid(quality: 60, maxWidth: 815) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      piilotaHinnat
      ruokaAnnokset {
        ... on Node {
          ... on ContentfulRuokaAnnos {
            id
            __typename
            ruokaAnnoksenNimi
            ruokaAnnoksenKuva {
              fluid(quality: 60, maxWidth: 400) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            ruokaAnnoksenKuvaus {
              ruokaAnnoksenKuvaus
            }
            ruokaAnnoksenHinta
            isonAnnoksenHinta
            pienenAnnoksenHinta
            hintahaitari
          }
          ... on ContentfulTeksti {
            id
            __typename
            otsikko
          }
        }
      }
      kategorianAlateksti {
        kategorianAlateksti
      }
    }
    pageContent: contentfulYleissivu(ketju: {sivupolku: { eq: $brand }}, sivupolku: { eq: $parentPage }) {
      id
      sivupolku
      sivunOtsikko
      sivunOtsikonVari
      paakuva {
        fluid(quality: 60, maxWidth: 1680) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      paakuvaMobiili {
        fluid(quality: 60, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      dynaaminenSisalto{
        ... on Node {
          ... on ContentfulMenu {
            id
            menuKategoriat {
              id
              kategorianNimi
            }
          }
          ... on ContentfulPaanosto {
            id
            otsikko
            linkkiosoite
            linkkiteksti
            naytaNavigointinappi
            sisaltoteksti {
              sisaltoteksti
            }
          }
        }
      }
    }
    brandSettings: contentfulKetju(sivupolku: { eq: $brand }) {
      id
      nimi
      sivupolku
      ravintoloidenYlasivu {
        sivupolku
      }
    }
  }
`;


